<script>
import VueApexCharts from 'vue-apexcharts'
import Vue from "vue";
import ApiSecure from "@/services/api/api-secure";
import Field from "@/components/Field.vue";
Vue.use(VueApexCharts)
//import responseFeedback from '@/assets/response_feedback.json'
export default {
  name: "Statistics",
  components: {
    Field,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartOptions: {
        colors: [ '#226f54', '#CF0A2C',],
        chart: {
          type: 'donut',
          dropShadow: {
            enabled: false,
          },
          selection: {
            enabled: false,
          }
        },
        noData: {
          style: {
            color: "grey"
          },
          text: this.$t('no-data')
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  shadow: false
                },
                value: {
                  shadow: false
                },
                total: {
                  show: false,
                  label: 'Всего',
                  color: '#373d3f',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              },
            }
          }
        }
      },
      locationCount: [],
      totalCount: [],
      type: '',
      results: [],
      totalResults: [],
      showResults: true,
      loading: true,
    }
  },
  computed: {
    showGlobal() {
      return !!this.totalCount && !!this.totalResults
    },
    locations() {
      if (this.locationCount.length > 0) {
        return this.locationCount.map(item => item.location)
      }
      else if (this.results) {
        return Object.keys(this.results)
      }
      return []
    },
    series() {
      if (this.type) {
        const i = this.locationCount.find(item => item.location === this.type)
        if (i) {
          return [
            i.passed_users,
            i.total_users - i.passed_users
          ]
        }
      }
      if (this.totalCount)
        return [
          this.totalCount.passed_users,
          this.totalCount.total_users - this.totalCount.passed_users
        ]
      return []
    },
    tasks() {
      if (this.type) {
        return this.results[this.type].tasks
      } else {
        return this.totalResults?.tasks
      }
    }

  },
  watch: {
    type() {
      this.showResults = false
      setTimeout(() => {
        this.showResults = true
      }, 500)
    }
  },
  async created() {
    this.locationCount = await ApiSecure.get('/stats/locations/')
    this.results = await ApiSecure.get('/stats/results/')
    try {
      this.totalCount = await ApiSecure.get('/stats/users/')
      this.totalResults = await ApiSecure.get('/stats/total_results/')
    } catch (e) {
      this.totalCount = null
      this.totalResults = null
      this.type = this.locations[0]
    }
    finally {
      this.loading = false
    }
    //this.results = JSON.parse(JSON.stringify(responseFeedback))
  }
}
</script>

<template>
  <v-container
    v-if="!loading"
    class="text-center"
    fill-height
    style="height: calc(100vh - 58px);"
  >
    <v-row
      v-if="showGlobal"
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="11"
      >
        <p class="text-h4">
          Статистика
        </p>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">
                  Локация
                </th>
                <th class="text-left">
                  Всего
                </th>
                <th class="text-left">
                  Прошло
                </th>
                <th class="text-left">
                  Не прошло
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left">
                  Всего
                </td>
                <td class="text-left">
                  {{ totalCount.total_users }}
                </td>
                <td class="text-left">
                  {{ totalCount.passed_users }}
                </td>
                <td class="text-left">
                  {{ totalCount.total_users - totalCount.passed_users }}
                </td>
              </tr>
              <tr
                v-for="item in locationCount"
                :key="item.location"
              >
                <td class="text-left">
                  {{ item.location }}
                </td>
                <td class="text-left">
                  {{ item.total_users }}
                </td>
                <td class="text-left">
                  {{ item.passed_users }}
                </td>
                <td class="text-left">
                  {{ item.total_users - item.passed_users }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      class="mt-16"
    >
      <v-col
        cols="11"
        sm="5"
        class="text-left"
      >
        <p class="text-h5">
          Статистика по
          <template v-if="type">
            локации <strong>{{ type }}</strong>
          </template>
          <strong v-else>
            всем локациям
          </strong>
        </p>
        <v-select
          v-if="locations.length > 1"
          v-model="type"
          :items="locations"
          label="Выберите локацию"
          outlined
          dense
          :clearable="showGlobal"
        />
      </v-col>
      <v-col
        cols="11"
        sm="6"
      >
        <apexchart
          type="donut"
          :options="{
            ...chartOptions,
            labels: ['Прошло','Не прошло']
          }"
          :series="series"
        />
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      class="mt-16"
    >
      <v-col cols="11">
        <p class="text-h6 text-left">
          Результаты опроса
        </p>
      </v-col>
      <v-col
        cols="11"
      >
        <v-progress-linear
          :value="33"
          :buffer-value="66"
          color="primary"
          background-color="warning"
          class="success mb-4"
          height="25"
        >
          <div
            style="
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            width: 100%;
            height: 100%;
            line-height: 1.6;
            color: white"
          >
            <span>{{ "менее 40%" }}</span>
            <span>{{ "41% - 74%" }}</span>
            <span>{{ "более 75 %" }}</span>
          </div>
        </v-progress-linear>
      </v-col>
      <v-col cols="11">
        <v-expansion-panels
          :disabled="!showResults"
        >
          <v-expansion-panel
            v-for="({fieldsSchema, description},i) in tasks"
            :key="i"
          >
            <v-expansion-panel-header>
              <strong>{{ description }}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-left">
              <template
                v-for="(schema, index) in fieldsSchema"
              >
                <p
                  v-if="!!schema.name && schema.name !== 'NaN'"
                  :key="`name-${index}`"
                  class="name"
                >
                  {{ schema.name }}
                </p>
                <Field
                  v-if="schema.type !== 'nan'"
                  :key="`field-${index}`"
                  :schema="schema"
                  :index="index"
                  dense
                  disabled
                  readonly
                  highlight
                />
                <v-divider
                  v-if="schema.type !== 'nan' && index !== fieldsSchema.length - 1"
                  :key="`divider-${index}`"
                  class="mb-5"
                />
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>